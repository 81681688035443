import { pageData } from './pageData.js'
import { zCreateTraderForAdminEndpointInput } from '@/general/src/trader/routes/createTraderForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { getOneEnv } from '@/webapp/src/lib/env.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const AdminTraderNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const isLocal = getOneEnv('HOST_ENV') === 'local'
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('')
  const [newTraderSerialNumber, setNewTraderSerialNumber] = useState<number>()

  const createTraderForAdmin = trpc.createTraderForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      customerDepositInterestShare: '',
      customerWithdrawInterestShare: '',
      teamleadCustomerDepositBonusShare: '0',
      teamleadCustomerWithdrawBonusShare: '0',
      name: '',
      phone: '',
      email: '',
      telegram: '',
      referrerSerialNumber: '' as never as number,
    },
    validationSchema: zCreateTraderForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await createTraderForAdmin.mutateAsync({
        ...valuesInput,
      })

      setNewPassword(res.trader.password)
      setNewTraderSerialNumber(res.trader.serialNumber)

      if (!isLocal) {
        navigate(adminTraderEditRoute.get({ traderSerialNumber: res.trader.serialNumber }))
      }
    },
    successMessage: 'Трейдер создан',
    resetOnSuccess: true,
  })

  return (
    <Block fcnw>
      <Segment title="Новый трейдер" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Textfieldy label="Серийный номер пригласившего трейдера" {...formy.getFieldProps('referrerSerialNumber')} />
          <Textfieldy
            label="Процент от клиентского депозита"
            {...formy.getFieldProps('customerDepositInterestShare')}
          />
          <Textfieldy
            label="Процент от клиентского виздрава"
            {...formy.getFieldProps('customerWithdrawInterestShare')}
          />
          <Textfieldy
            label="Тимлдиский процент от клиентского депозита"
            {...formy.getFieldProps('teamleadCustomerDepositBonusShare')}
          />
          <Textfieldy
            label="Тимлидский процент от клиентского виздрава"
            {...formy.getFieldProps('teamleadCustomerWithdrawBonusShare')}
          />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать
            </Button>
          </Buttons>

          {isLocal && newPassword && newTraderSerialNumber && (
            <>
              <p>
                Пароль нового трейдера: <input type="text" value={newPassword} contentEditable={false} />
              </p>
              <p>
                Передайте пароль трейдеру, которого вы добавили. Для безопасности, нужно, чтобы он его сменил на свой.
              </p>

              <p>
                <a href={adminTraderEditRoute.get({ traderSerialNumber: newTraderSerialNumber })}>
                  Перейти на страницу настроек нового трейдера
                </a>
              </p>
            </>
          )}
        </FormItems>
      </Segment>
    </Block>
  )
})
